<template>
  <div>
    <h4>SEGUMIENTO PACIENTES INICIADOS</h4>
    <app-ctrl-year-month
      v-model="date"
      @input="getList()"
    ></app-ctrl-year-month>
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th>DOCTOR</th>
          <th>PACIENTE</th>
          <th>FECHA INICIO</th>
          <th>P. CITA</th>
          <th>AVANCE</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>{{ l.employee_name }}</td>
          <td>{{ l.client_name }}</td>
          <td>{{ l.initiated_date | dateTime }}</td>
          <td>
            <span v-show="l.next_date">{{ l.next_date | date }}</span>
            <span
              v-show="!l.next_date && l.date_to_contact"
              :title="l.date_to_contact"
            >
              CRM
            </span>
            <span v-show="!l.next_date && !l.date_to_contact"
              >No tiene cita
            </span>
          </td>
          <td>{{ l.progress | percent }}</td>
        </tr>
        <tr>
          <th>TOTAL</th>
          <th colspan="5">{{ list.length }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    date: {},
    list: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    getList() {
      DentalClinicService.getReportProform({
        ...this.date,
        per_page: 10000
      }).then(res => {
        this.list = res;
      });
    }
  }
};
</script>

<style></style>
